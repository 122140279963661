
import axios from "axios"

import FormData from 'form-data'


class CertificatesApi {

    constructor(
        certHost, certTokenPrefix, certLicensePrefix, certDownloadPrefix
    ) {
        this.certHost = certHost
        this.certTokenPrefix = certTokenPrefix
        this.certLicensePrefix = certLicensePrefix
        this.certFownloadPrefix = certDownloadPrefix

    }

    async downloadCertificate(certificateDownloadUrl) {


        var config = {
            method: 'GET',
            url: certificateDownloadUrl,
            responseType: "blob",
            headers: {}
        };

        const response = await axios(config)
        return new Blob([response.data], { type: 'application/pdf' })

    }

    async createCertificate(certificateMetadata) {
        let certResolve, certReject;
        let certCreate = new Promise(function (resolve, reject) {
            certResolve = resolve;
            certReject = reject;
        });
        let data = new FormData()
        data.append('preview_image', certificateMetadata.previewImage)
        data.append('creative_asset', certificateMetadata.name),

            data.append('url', certificateMetadata.url)

        data.append('contract', certificateMetadata.contract)
        data.append('genre', certificateMetadata.genre)

        certificateMetadata.copyrights.forEach((c, i) => {
            data.append('copyrights[' + i + ']', c)
        })
        data.append('token_id', certificateMetadata.tokenId)

        data.append('currency', certificateMetadata.currency)

        await axios.post(
            this.certHost + '/' + this.certApiUrl
            , data, {
                maxBodyLength: 'Infinity',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            }
        )
            .then(resp => {
                certResolve(resp.data)
            })
            .catch(err => {
                certReject(err)
            })
        await certCreate
        return certCreate
    }


    async createTokenCertificate(certificateMetadata) {


        let certResolve, certReject;
        let certCreate = new Promise(function (resolve, reject) {
            certResolve = resolve;
            certReject = reject;
        });
        let data = new FormData()
        data.append('preview_image', certificateMetadata.previewImage)
        data.append('creativeAsset', certificateMetadata.creativeAsset)
        data.append('contract', certificateMetadata.contract)
        data.append('tokenId', certificateMetadata.tokenId)
        !data.append('format', certificateMetadata.format)
        data.append('owner', certificateMetadata.owner)
        data.append('creationDate', certificateMetadata.creationDate)
        data.append('edition', certificateMetadata.edition)
        data.append('quantity', certificateMetadata.quantity)
        data.append('type', certificateMetadata.type)
        certificateMetadata.copyrights.forEach((c, i) => {
            data.append('copyrights[' + i + ']', c)
        })


        let index = 0;
        for (let artist in certificateMetadata.artists) {
            if (certificateMetadata.artists.hasOwnProperty(artist)) {
                console.log(`artists[${index}]`, artist)
                data.append(`artists[${index}]`, artist);
                index++;
            }
        }

        await axios.post(
            this.certHost  + this.certTokenPrefix
            , data, {
                maxBodyLength: 'Infinity',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            }
        )
            .then(resp => {
                certResolve(resp.data)
            })
            .catch(err => {
                certReject(err)
            })
        await certCreate
        return certCreate
    }



}

export default CertificatesApi