import { createApp } from "vue";
import App from "./App.vue";
import { Buffer } from "buffer";
import axios from "axios";
import VueAxios from "vue-axios";
import MasonryWall from "@yeger/vue-masonry-wall";
import VueApexCharts from "vue3-apexcharts";
import vSelect from "vue-select";
import VueQRCodeComponent from "vue-qr-generator";
import BaseIcon from "@/components/BaseIcon.vue";
import VuePdf from "vue3-pdfjs";
import vClickOutside from "click-outside-vue3";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import { AVPlugin } from "vue-audio-visual";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import { keyStores } from "near-api-js";
import vArtNearApi from "@v-art-protocol/v-art-protocol-near-sdk";
import Maska from "maska";

import router from "./router";
import { store } from "./store";

import "./assets/scss/style.scss";

export const afoofe = [1, 2, 3];
const app = createApp(App);

const config = {
  contract: {
    adminId: process.env.VUE_APP_MASTER_ACCOUNT_ID,
    nftContractId: process.env.VUE_APP_NFT_CONTRACT_ID,
    marketContractId: process.env.VUE_APP_MARKET_CONTRACT_ID,
    licenseContractId: process.env.VUE_APP_LICENSE_ID,
    net: process.env.VUE_APP_NET,
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: process.env.VUE_APP_NODE_URL,
    walletUrl: process.env.VUE_APP_WALLET_URL,
    helperUrl: process.env.VUE_APP_HELPER_URL,
    explorerUrl: process.env.VUE_APP_EXPLORER_URL,
  },

  backendUrl: process.env.VUE_APP_URL,

  graphUrl: process.env.VUE_APP_GRAPH_URL,

  ipfs: {
    apiKey: process.env.VUE_APP_API_KEY,
    apiKeySecret: process.env.VUE_APP_SECRET_KEY,
    gateway: process.env.VUE_APP_GATEWAY_URL,
  },

  certificate: {
    host: process.env.VUE_APP_CERTIFICATE_HOST,
    tokenPrefix: process.env.VUE_APP_CERTIFICATE_TOKEN_PREFIX,
    licensePrefix: process.env.VUE_APP_CERTIFICATE_LICENSE_PREFIX,
    downloadPrefix: process.env.VUE_APP_CERTIFICATE_DOWNLOAD_PREFIX,
  },
};

// const config = {
//   contract: {
//     adminId: "v-art-demo-2.testnet",
//     nftContractId: "nft.v-art-demo-2.testnet",
//     marketContractId: "market.v-art-demo-2.testnet",
//     licenseContractId: "license.v-art-demo-2.testnet",
//     net: "testnet",
//     keyStore: new keyStores.BrowserLocalStorageKeyStore(),
//     nodeUrl: "https://rpc.testnet.near.org",
//     walletUrl: "https://wallet.testnet.near.org",
//     helperUrl: "https://helper.testnet.near.org",
//     explorerUrl: "https://explorer.testnet.near.org",
//   },

//   backendUrl: "https://licensing-testnet.v-art.digital:3100",

//   graphUrl:
//     "https://api.thegraph.com/subgraphs/name/valexandrovich/valexa-license",

//   ipfs: {
//     apiKey: "b77cb937f643a0f34be6",
//     apiKeySecret:
//       "a923a0dfe3acd142ca978a2a3436ecb7a6fe9a41ead143e495d1525cb9eb2eab",
//     gateway: "https://gateway.pinata.cloud/ipfs/",
//   },

//   certificate: {
//     host: "https://certificate.v-art.digital",
//     // host: "http://localhost",
//     tokenPrefix: "/api/certificates/v2/token",
//     licensePrefix: "/api/certificates/v2/license",
//     downloadPrefix: "/download",
//   },
// };

const vArtApiInstance = new vArtNearApi(config);

const baseUrl = process.env.VUE_APP_URL;

let currPriceCoefficient = 0;
axios.get(`${baseUrl}/api/currency`).then((response) => {
  currPriceCoefficient = response.data;
});

window.Buffer = Buffer;

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(Maska);
app.use(MasonryWall);
app.use(vClickOutside);
app.use(VuePdf);
app.use(AVPlugin);
app.use(Vue3VideoPlayer, {
  lang: "en",
});
app.use(VueApexCharts);
app.component("base-icon", BaseIcon);
app.component("v-select", vSelect);
app.component("qr-code", VueQRCodeComponent);
app.provide("axios", app.config.globalProperties.axios);
app.provide("vArtApi", vArtApiInstance);
app.mount("#app");

app.config.globalProperties.$filters = {
  priceToNear(price) {
    // return (+price).toFixed(2)
    console.log(+price / 10 ** 24, "(+price / 10 ** 24)");
    return (+price / 10 ** 24).toFixed(2);
  },
  priceNearToUsd(price) {
    // return (+price * currPriceCoefficient?.near?.usd).toFixed(2)
    return (
      (+price / 10 ** 24) *
      currPriceCoefficient?.near?.usd
    ).toFixed(2);
  },
  lowestPriceNear(array) {
    let lowestPrice = null;
    array.forEach((item) => {
      if (!lowestPrice || lowestPrice > item.price) {
        lowestPrice = item.price;
      }
    });

    // return (+lowestPrice).toFixed(2)
    return (+lowestPrice / 10 ** 24).toFixed(2);
  },
  highestPriceNear(array) {
    let lowestPrice = null;
    array.forEach((item) => {
      if (!lowestPrice || lowestPrice < item.price) {
        lowestPrice = item.price;
      }
    });

    // return (+lowestPrice).toFixed(2)
    return (+lowestPrice / 10 ** 24).toFixed(2);
  },
  lowestPriceUsd(array) {
    let lowestPrice = null;
    array.forEach((item) => {
      if (!lowestPrice || lowestPrice > item.price) {
        lowestPrice = item.price;
      }
    });

    // return (+lowestPrice * currPriceCoefficient?.near?.usd).toFixed(2)
    return (
      (+lowestPrice / 10 ** 24) *
      currPriceCoefficient?.near?.usd
    ).toFixed(2);
  },
  highestPriceUsd(array) {
    let lowestPrice = null;
    array.forEach((item) => {
      if (!lowestPrice || lowestPrice < item.price) {
        lowestPrice = item.price;
      }
    });

    // return (+lowestPrice * currPriceCoefficient?.near?.usd).toFixed(2)
    return (
      (+lowestPrice / 10 ** 24) *
      currPriceCoefficient?.near?.usd
    ).toFixed(2);
  },
};
