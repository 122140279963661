<template>
  <div
    :class="{
      'app-hidden': commonStore.loader || commonStore.hidden,
    }"
  >
    <loader v-if="commonStore.loader" />
    <router-view :key="$route.fullPath" />
    <AppBaseDialog v-if="commonStore.dialogParams.open" />
    <!-- <create-new-offer /> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./store/auth.store";
import { useCommonStore } from "@/store/common.store";

import Loader from "./components/BaseLoader.vue";
import AppBaseDialog from "./components/BaseDialog.vue";
// import CreateNewOffer from "./pages/minter/CreateNewOffer.vue";

export default defineComponent({
  components: { Loader, AppBaseDialog },

  mounted() {
    window.addEventListener("resize", () => {
      this.commonStore.$patch({ windowWidth: window.innerWidth });
    });
  },

  unmounted() {
    window.removeEventListener("resize");
  },

  setup() {
    const vArtApi = inject("vArtApi");
    const router = useRouter();
    const authStore = useAuthStore();
    const commonStore = useCommonStore();

    onMounted(async () => {
      commonStore.$patch({ windowWidth: window.innerWidth });

      try {
        await vArtApi.init();

        console.log(vArtApi, "this.$vArtApi");

        let account = vArtApi.getAccount();

        console.log(account, "account");

        if (account) authStore.$patch({ user: account });
        else authStore.$patch({ user: "" });
      } catch (error) {
        console.log("Initialization error: ", error);
      }
    });

    authStore.$subscribe((_, state) => {
      if (!state.user) {
        router.push({ name: "auth" });
      }
    });

    return { commonStore };
  },
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /* font-family: "Stolzl"; */
}

.app-hidden {
  overflow: hidden;
  height: 100vh;
}
</style>
