<template>
  <div class="base-dialog" @click="overlayClick">
    <div class="base-dialog__container">
      <div v-if="dialogParams.headIcon" class="base-dialog__head-icon">
        <img :src="`/img/${dialogParams.headIcon}.svg`" />
      </div>

      <p class="base-dialog__info">
        {{ dialogParams.info }}
      </p>

      <div
        class="base-dialog__buttons"
        :class="{
          'base-dialog__buttons--multiple':
            dialogParams.cancel && dialogParams.confirm,
        }"
      >
        <button
          v-if="dialogParams.cancel"
          class="btn btn-light"
          @click="closeDialog"
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.82 5.92999L3.75 12L9.82 18.07"
              stroke="#0000FF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.7499 12H3.91992"
              stroke="#0000FF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span> Keep </span>
        </button>

        <button class="btn btn-blue" @click="confirm">
          <img
            src="/img/remove.svg"
            v-if="dialogParams.confirmIcon == 'remove'"
          />

          {{ dialogParams.confirm }}

          <div
            class="base-dialog__button-next"
            v-if="dialogParams.confirmIcon == 'next'"
          >
            <img src="/img/arrow-right.svg" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useCommonStore } from "@/store/common.store";

export default defineComponent({
  name: "BaseDialog",
  setup() {
    const commonStore = useCommonStore();
    const dialogParams = commonStore.dialogParams.data;

    function overlayClick(event) {
      if (event.target.classList.value === "base-dialog") {
        closeDialog();
      }
    }

    function confirm() {
      commonStore.confirmDialog();
    }

    function closeDialog() {
      commonStore.closeDialog();
    }

    return {
      dialogParams,
      overlayClick,
      closeDialog,
      confirm,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/variables";

.base-dialog {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 5;
  background: rgba(41, 40, 57, 0.66);

  &__container {
    box-sizing: border-box;
    width: 594px;
    background: white;
    border: 0.5px solid #c0bfdc;
    border-radius: $R24;
    padding: 64px 40px;
    min-height: 424px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  &__buttons {
    width: calc(100% - 80px);
    margin-top: auto;
    padding-top: 20px;

    &--multiple {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }
  }

  &__button {
    &-next {
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }

  &__head-icon {
    margin-bottom: 48px;
  }

  &__info {
    color: $color-gray-dark;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }

  &__buttons {
    &--multiple {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    .btn {
      width: 100%;
      justify-content: center;
      position: relative;
      font-size: 18px;

      img {
        margin-right: 12px;
      }
    }
  }
}
</style>

