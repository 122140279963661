import { defineStore } from "pinia";

export const useCommonStore = defineStore({
  id: "Common",
  state: () => ({
    loader: false,
    modal: false,
    hidden: false,
    windowWidth: 10,
    dialogParamsData: {
      open: false,
      confirm: false,
      // component: undefined,
      // maxWidth: 544,
      // data: {}
    },
    licenseTypes: ["", "Exclusive", "Non-exclusive", "Sole"],
    licenseGoals: [
      "",
      "Commercial use",
      "Demonstration / Creative",
      "AI license",
    ],
    nftStatuses: ["On sale", "Not listed", "Licenses available"],
    licenseRegions: ["CV", "AD", "AE", "EN", "TW", "TZ", "UA", "ZW"],
    africaRegion: [
      "Algeria",
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cameroon",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Congo (Republic of the Congo)",
      "Congo (Democratic Republic of the Congo)",
      "Djibouti",
      "Egypt",
      "Equatorial Guinea",
      "Eritrea",
      "Eswatini",
      "Ethiopia",
      "Gabon",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Ivory Coast",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Libya",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Rwanda",
      "Sao Tome and Principe",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Somalia",
      "South Sudan",
      "Tanzania",
      "Togo",
      "Tunisia",
      "Uganda",
      "Zambia",
      "Zimbabwe",
    ],
    northAndCentralAmericaRegion: [
      "Anguilla",
      "Antigua and Barbuda",
      "Aruba",
      "Bahamas",
      "Barbados",
      "Belize",
      "Bermuda",
      "Bonaire",
      "British Virgin Islands",
      "Canada",
      "Cayman Islands",
      "Clipperton Island",
      "Costa Rica",
      "Curaçao",
      "Dominica",
      "Dominican Republic",
      "El Salvador",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guatemala",
      "Haiti",
      "Honduras",
      "Jamaica",
      "Martinique",
      "Mexico",
      "Montserrat",
      "Nicaragua",
      "Panama",
      "Puerto Rico",
      "Saint Barthélemy",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin",
      "Saint Vincent and the Grenadines",
      "Saba",
      "Saint Eustatius",
      "Sint Maarten",
      "Trinidad and Tobago",
      "Turks and Caicos Islands",
      "USA",
      "U.S. Virgin Islands",
    ],
    southAmericaRegion: [
      "Antarctica",
      "Argentina",
      "Bolivia",
      "Bouvet Island",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Falkland Islands (Malvinas)",
      "French Guyana",
      "Guyana",
      "Paraguay",
      "Peru",
      "South Georgia and the South Sandwich Islands",
      "Suriname",
      "Uruguay",
    ],
    asiaRegion: [
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "Brunei",
      "Cambodia",
      "China",
      "Cyprus",
      "Georgia",
      "Hong Kong Special Administrative Region of the People`s Republic of China",
      "India",
      "Indonesia",
      "Iraq",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Lebanon",
      "Macao Special Administrative Region of the People`s Republic of China",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "Oman",
      "Pakistan",
      "Philippines",
      "Qatar",
      "Saudi Arabia Singapore",
      "South Korea",
      "Sri Lanka",
      "Tajikistan",
      "Thailand",
      "Timor-Leste",
      "Turkey",
      "Turkmenistan",
      "United Arab Emiratesinistrative Reg",
      "Uzbekistan",
      "Vietnam",
      "Yemen",
    ],
    europeRegion: [
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Georgia",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Ireland",
      "Italy",
      "Kazakhstan",
      "Kosovo",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Malta",
      "Moldova",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "North Macedonia",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "San Marino",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Ukraine",
      "United Kingdom",
      "Vatican City (Holy See)",
    ],
    oceaniaRegion: [
      "Australia",
      "Christmas Island",
      "Cocos (Keeling) Islands",
      "Cook Islands",
      "Fiji",
      "French Polynesia",
      "Guam",
      "Heard Island and McDonald Islands",
      "Kiribati",
      "Marshall Islands",
      "Micronesia (Federated States of)",
      "Nauru",
      "New Caledonia",
      "New Zealand",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands",
      "Palau",
      "Papua New Guinea",
      "Pitcairn",
      "Samoa",
      "Solomon Islands",
      "Tokelau",
      "Tonga",
      "Tuvalu",
      "Vanuatu",
      "Wallis and Futuna Islands",
    ],
    useStates: [
      "USA, Alabama",
      "USA, Alaska",
      "USA, Arizona",
      "USA, Arkansas",
      "USA, California",
      "USA, Colorado",
      "USA, Connecticut",
      "USA, Delaware",
      "USA, Florida",
      "USA, Georgia",
      "USA, Hawaii",
      "USA, Idaho",
      "USA, Illinois",
      "USA, Indiana",
      "USA, Iowa",
      "USA, Kansas",
      "USA, Kentucky",
      "USA, Louisiana",
      "USA, Maine",
      "USA, Maryland",
      "USA, Massachusetts",
      "USA, Michigan",
      "USA, Minnesota",
      "USA, Mississippi",
      "USA, Missouri",
      "USA, Montana",
      "USA, Nebraska",
      "USA, Nevada",
      "USA, New Hampshire",
      "USA, New Jersey",
      "USA, New Mexico",
      "USA, New York",
      "USA, North Carolina",
      "USA, North Dakota",
      "USA, Ohio",
      "USA, Oklahoma",
      "USA, Oregon",
      "USA, Pennsylvania",
      "USA, Rhode Island",
      "USA, South Carolina",
      "USA, South Dakota",
      "USA, Tennessee",
      "USA, Texas",
      "USA, Utah",
      "USA, Vermont",
      "USA, Virginia",
      "USA, Washington",
      "USA, West Virginia",
      "USA, Wisconsin",
      "USA, Wyoming",
    ],
  }),
  getters: {
    dialogParams: (state) => state.dialogParamsData,
    isDesktop: (state) => state.windowWidth >= 1300,
    isLaptop: (state) =>
      state.windowWidth <= 1280 && state.windowWidth >= 769,
    isMobile: (state) => state.windowWidth <= 769,
  },
  actions: {
    async bindUser() {},
    initNewDialog(payload) {
      payload.open = true;
      payload.confirm = false;

      this.$patch({
        dialogParamsData: payload,
      });
    },
    confirmDialog() {
      this.$patch({
        dialogParamsData: {
          confirm: true,
          open: false,
        },
      });
    },
    closeDialog() {
      this.$patch({
        dialogParamsData: {
          open: false,
        },
      });
    },
  },
});
